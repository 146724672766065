<template>
    <div>
        <v-tour name="dyadeyTour" :steps="steps" :callbacks="callbacks"></v-tour>
    </div>
</template>

<script>
import Vue from 'vue';
import VueTour from 'vue-tour';
Vue.use(VueTour);
require('vue-tour/dist/vue-tour.css');

export default {
    components: {
        VueTour
    },
    data() {
        return {
            callbacks: {
                onFinish: this.finish,
                onSkip: this.skip,
                onStart: this.start,
                onNextStep: this.nextStep
            },
            done: this.$cookies.get('tour'),
            steps: [],
            user: this.$root.user
        }
    },
    created() {
        this.steps = this.$store.getters.tour;
    },
    mounted() {
        if (window.location.search.indexOf('tour') !== -1) {
            if (this.user !== false && this.done == null) {
                this.$tours['dyadeyTour'].start();
            }
        }
    },
    methods: {
        finish() {
            this.$cookies.set('tour', 'finished', '90d', '/', '', true, 'Strict');
        },
        skip() {
            this.$cookies.set('tour', 'skipped', '90d', '/', '', true, 'Strict');
        },
        start() {
            //console.log('tour started');
        },
        nextStep(currentStep) {
            let nextStep = this.$tours['dyadeyTour'].steps[currentStep + 1];
            if (nextStep.target === '[data-tour="my-feed"]') {
                // get header element bounding box so we can calculate height as it has a fixed position and can otherwise obscure the tour step
                let headerBox = document.querySelector('.c-page-head').getBoundingClientRect();
                // get step bounding box
                let stepBox = document.querySelector(nextStep.target).getBoundingClientRect();
                window.scrollTo({
                    top: Math.ceil(stepBox.top - (headerBox.height * 1.25)),
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    }
}
</script>
